import React from 'react'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem } from 'reactstrap'
import { Link } from 'gatsby'


export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <header className={'header ' + (this.props && this.props.bgWhite ? 'bg-white' : '')}>
        <Navbar light expand="md">
        <Container>
          <div className="navbar-bar d-block d-md-none">
            <NavbarToggler className="mr-O ml-auto" onClick={this.toggle} />
          </div>
          <Collapse className={(this.state.isOpen ? "openned" : "closed")} navbar>
            <Nav className="ml-auto mr-0" navbar>
              <NavItem>
                <Link to="/" className="nav-link" activeClassName="active">Accueil</Link>
              </NavItem>
              <NavItem>
                <Link to="/services" className="nav-link" activeClassName="active">Services</Link>
              </NavItem>
              <NavItem>
                <Link to="/contact" className="nav-link" activeClassName="active">Contact</Link>
              </NavItem>
            </Nav>
          </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

