import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby'

const Footer = () => 
  <footer>
    <Container>
      <Row>
        <Col sm="12" md="5" className="left">
          <p><Link to="/">laboiteaoutils.net</Link> @ copyright 2022</p>
        </Col>
        <Col sm="12" md="7" className="right text-right">
          <ul className="nav-footer">
            <li>
              <Link to="mentions-legales">Mentions légales</Link>
            </li>
            <li>
              <Link to="mentions-legales/rgpd">Données personnelles</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
          
        </Col>
      </Row>
    </Container>
  </footer>


export default Footer